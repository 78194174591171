import React from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import DisplayMessage from "./components/DisplayMessage";
import InputMessage from "./components/InputMessage";

function App() {
  const navigate = useNavigate(); // historyを用意する
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold">One Time Messaging</h1>
      <Routes>
        <Route path="/" element={<InputMessage navigate={navigate} />}></Route>
        <Route path="/messages/:key" element={<DisplayMessage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
