import React from "react";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../firestore";

const DisplayMessage = () => {
  const [message, setMessage] = React.useState<string | null>(null);
  const { key } = useParams();
  if (!key) return <></>;
  const dispMessage = async (key: string) => {
    if (!key) return;

    const docRef = doc(db, "messages", key);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      alert("データが存在しません。すでに取得済みの可能性があります");
      return;
    }
    const savedMessage = docSnap.data();
    setMessage(savedMessage.message);
    console.log("Document data:", docSnap.data());
    await deleteDoc(docRef);
  };
  return message ? (
    <>{message}</>
  ) : (
    <>
      <p className="my-4">
        メッセージを表示したい場合は以下のボタンをクリックしてください。１度表示するとメッセージは削除されます。
      </p>
      <button
        id="send"
        className="
            block
            w-full
            mx-auto
            my-6
            px-6
            py-2.5
            bg-black
            text-white
            font-medium
            text-xs
            leading-tight
            uppercase
            rounded
            shadow-md
            hover:bg-gray-600
            hover:shadow-lg
            focus:bg-gray-600
            focus:shadow-lg
            focus:outline-none
            focus:ring-0
            transition
            duration-150
            ease-in-out"
        onClick={() => dispMessage(key)}
      >
        Dipslay Message
      </button>
    </>
  );
};

export default DisplayMessage;
