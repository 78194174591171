import React from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firestore";

const InputMessage = ({ navigate }: { navigate: (arg: string) => void }) => {
  const [message, setMessage] = React.useState<string>("");
  const [messageKey, setMessageKey] = React.useState<string | null>(null);
  const sendMessage = async () => {
    try {
      if (!message || message.length === 0) {
        alert("内容を入力してください");
        return;
      }
      const docRef = await addDoc(collection(db, "messages"), {
        message: message,
      });
      console.log("Document written with ID: ", docRef.id);
      setMessageKey(docRef.id);
      navigate("/messages/" + docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  return (
    <div>
      {messageKey ? (
        <>{`/${messageKey}`}</>
      ) : (
        <>
          <p className="my-4">伝えたい内容を入力して、送信を押してください。</p>
          <textarea
            id="save-message"
            className="
                block
                w-full
                h-48
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button
            id="send"
            className="
                block
                w-full
                mx-auto
                my-6
                px-6
                py-2.5
                bg-black
                text-white
                font-medium
                text-xs
                leading-tight
                uppercase
                rounded
                shadow-md
                hover:bg-gray-600
                hover:shadow-lg
                focus:bg-gray-600
                focus:shadow-lg
                focus:outline-none
                focus:ring-0
                transition
                duration-150
                ease-in-out"
            onClick={() => sendMessage()}
          >
            Send
          </button>
        </>
      )}
    </div>
  );
};
export default InputMessage;
